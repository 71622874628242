:root {
  --orange: #FD6326; /* Une teinte orange */
  --orange-clair: #FD632626; /* Une teinte orange clair */
  --noir: #000; /* Une couleur pour le texte */
  --gris-clair: #C7CCCF30; /* Une couleur pour le texte */
	--vert:#81c81b;
	--vert-clair:#B9EC6E;
}

button, input, optgroup, select, textarea {
	background-color: #ffffff;
    border: 0;
    padding: 12px 40px;
    -webkit-box-shadow: 10px 10px 18px 0 #AAAACC80;
    box-shadow: 10px 10px 18px 0 #AAAACC80;
    border-radius: 40px;
	text-align:center;
}

button[type=submit] {
	border: 0;	
	background-color: var(--vert);
	border-radius:40px;
	padding: 12px 40px;
	margin-top:40px;
}

h1 {
    font-size: 1.8rem;
    margin-top: 1rem;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6, p, a, span, strong, div {
	color:var(--noir)!important;
	font-family: "Inter", serif;
}

button {
    border-radius: 8px;
    font-size: 1.1rem;
}

.btn {
	border-radius:30px;
    padding: 10px 20px;
    font-size: 1rem !important;
}

/**
***
HOME SCREEN
***
**/
.btnContent {
	display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 3rem;
}

/**
***
LOGIN SCREEN
***
**/
.logo {
    width: 20vw;
    max-width: 100px;	
}

.loginScreen {
	display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.loginForm {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 3rem;
}

.container-fluid {
    background-color: #f8f9fa;
}

/**
***
LOGIN SCREEN
***
**/

.btnColor {
	background-color:var(--vert);
	color:var(--noir);
}

.btnNoColor {
	color:var(--noir);
}

.mainContent {
	padding:80px 0;
}

.contentCard {
	border-left: 0;
    background:var(--vert-clair);
    border-radius: 10px;
}

.input-black {
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 0;
    padding: 10px;
}

.input-black::placeholder {
    color: #fff;
    opacity: 1;
}

.btn-yellow {
    background-color: #FED730;
    color: #000;
    border: none;
    padding: 10px;
    font-weight: bold;
    border-radius: 0;
}

.btn-yellow:disabled {
    opacity: 0.6;
}

.profileInfo{
	padding-bottom:40px!important;
}

.Content{margin-top:80px!important;margin-bottom:80px!important}
.contentMessages{max-height:calc(100vh - 100px);}
.barreRecherche{position: fixed;bottom: 60px;width: 100%;}
.btn-close{height: 5px;width: 5px;margin: 10px 10px 0 0;}
.filtres{flex-direction: column!important;}


/**
***
CHAT
***
**/

.contentMessage {
	height: calc(100vh - 60px) !important;
	display: flex;
  	flex-direction: column;
}

/* Header */
.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 15px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #ddd;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.chat-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

/* Chat messages */
.chat-messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 60px 15px 0px;
}

.chat-date {
  text-align: center;
  color: #999;
  margin: 10px 0;
}

.chat-message {
  max-width: 70%;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 20px;
  word-wrap: break-word;
  -webkit-box-shadow: 10px 10px 18px 0 #AAAACC80;
  box-shadow: 10px 10px 18px 0 #AAAACC80;
  background-color: #fff;
	font-size: 0.9rem;
}

.chat-message.sent {
  margin-left: auto;
}

.chat-message.received {
  margin-right: auto;
}

.chat-time {
  font-size: 0.8rem;
  color: #999;
  text-align: right;
  margin-top: 5px;
}

/* Input */
.chat-input {
  display: flex;
  padding: 10px;
  background-color: #f8f9fa;
  width: 100%;
  z-index: 1000;
}

.chat-input input {
  flex-grow: 1;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ddd;
  box-shadow: none;
  border-radius: 30px;
}

.chat-input button {
  background: none;
  border: none;
  cursor: pointer;
	border-radius:30px;
}
